import type { Locales } from '@/i18n';
import { setLocale } from '@/stores/locale';
import { useEffect, type FC } from 'react';

interface LocaleSetterProps {
  locale: Locales;
}

const LocaleSetter: FC<LocaleSetterProps> = ({ locale }) => {
  useEffect(() => {
    setLocale(locale);
  }, [locale]);
  return <></>;
};

export default LocaleSetter;
